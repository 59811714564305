import { Socket, Channel } from "phoenix";
import { ScoreboardUpdate } from "../entities";
import config from "../config";

export class AppSocket {
  socket: Socket;

  constructor(token?: string) {
    if (token) {
      this.socket = new Socket(`${config.WEBSOCKET_HOST}socket`, {
        params: { token }
      });
    } else {
      this.socket = new Socket(`${config.WEBSOCKET_HOST}socket`, {});
    }
    this.socket.connect();
  }

  connect(scoreboard_id: String): [Channel, Promise<ScoreboardUpdate>] {
    let channel = this.socket.channel(`scoreboard:${scoreboard_id}`);
    let join = channel.join();
    let initial_state = new Promise<ScoreboardUpdate>((resolve, reject) => {
      join
        .receive("ok", (resp: any) => {
          resolve(resp);
        })
        .receive("error", (resp: String) => {
          reject(`Failed ${resp}`);
        });
    });
    return [channel, initial_state];
  }
}
