import React from "react";
import { Player, ScoreboardUpdate } from "../../entities";
import * as Styled from "./styled"

type PlayerProps = {
  player: Player;
  side: Styled.Side;
};

type ScoreboardProps = {
  scoreboard: ScoreboardUpdate;
};

const displayPlayerScore = (score: number) => {
  if (score === null || isNaN(score)) {
    return ""
  } else {
    return score
  }
}

const PlayerArea = (props: PlayerProps) => (
  <Styled.PlayerDiv side={props.side}>
    <Styled.PlayerName side={props.side}>
      <Styled.PlayerTag side={props.side}>{props.player.tag}</Styled.PlayerTag>{" "}
      {props.player.name}
    </Styled.PlayerName>
    <Styled.PlayerScore side={props.side}>{displayPlayerScore(props.player.score)}</Styled.PlayerScore>
  </Styled.PlayerDiv>
);

export const TekkenScoreboard = (props: ScoreboardProps) => {
  return (
    <Styled.ScoreboardDiv>
      <PlayerArea
        side={"left"}
        player={props.scoreboard.player_one}
      ></PlayerArea>
      <Styled.Round>{props.scoreboard.round}</Styled.Round>
      <PlayerArea
        side={"right"}
        player={props.scoreboard.player_two}
      ></PlayerArea>
    </Styled.ScoreboardDiv>
  );
};
