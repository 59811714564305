import React, { FunctionComponent } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "./components/Navbar";
import { Home } from "./pages/Home";
import { ScoreboardManager } from "./pages/ScoreboardManager";
import { ScoreboardAdmin } from "./pages/ScoreboardAdmin";
import { Scoreboard } from "./pages/Scoreboard";
import { Login } from "./pages/Login";
import { AuthenticatedProvider } from "./contexts/AuthenticatedContext";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { ProtectedRoute } from "./components/ProtectedRoute";

const Contexts: FunctionComponent = props => (
  <AuthenticatedProvider>
    {props.children}
  </AuthenticatedProvider>
);

const App: React.FC = () => {
  return (
    <div className="App">
      <Contexts>
        <Router>
          <Navbar />
          <Switch>
            <ProtectedRoute
              path="/scoreboards/manage/:id"
              component={ScoreboardAdmin}
            />
            <Route path="/scoreboards/view/:id" component={Scoreboard} />
            <ProtectedRoute path="/scoreboards" component={ScoreboardManager} />
            <Route path="/login" component={Login} />
            <Route path="/" component={Home} exact />
            <Route>
              <>Page not found</>
            </Route>
          </Switch>
        </Router>
      </Contexts>
    </div>
  );
};

export default App;
