import React, { useState, useEffect } from "react";
import { useParams, RouteComponentProps } from "react-router-dom";
import { api } from "../../api";
import { AppSocket } from "../../socket";
import { Player, ScoreboardUpdate } from "../../entities";
import { TekkenScoreboard } from "../../components/TekkenScoreboard";

export const Scoreboard = (props: RouteComponentProps) => {
  const { id } = useParams();
  const [round, setRound] = useState<string>();
  const [playerOne, setPlayerOne] = useState<Player>();
  const [playerTwo, setPlayerTwo] = useState<Player>();

  useEffect(() => {
    const init = async () => {
      const token = await api.getToken().catch(() => null)
      const socket = token ? new AppSocket(token) : new AppSocket();
      const [channel, initial_state] = socket.connect(id as String);
      const state = await initial_state;
      setRound(state.round);
      setPlayerOne(state.player_one);
      setPlayerTwo(state.player_two);
      channel.on("scoreboard_update", (update: { body: ScoreboardUpdate }) => {
        console.log("recieved", update);
        setRound(update?.body?.round);
        setPlayerOne(update.body.player_one);
        setPlayerTwo(update.body.player_two);
      });
    };
    init();
  }, [id]);

  return (
    <>
      {round !== undefined && playerOne && playerTwo && (
        <TekkenScoreboard
          scoreboard={{
            round: round,
            player_one: playerOne,
            player_two: playerTwo
          }}
        ></TekkenScoreboard>
      )}
    </>
  );
};
