import React, { useState, useEffect } from "react";
import { AppSocket } from "../../socket";
import { Player, ScoreboardUpdate } from "../../entities";
import { Channel } from "phoenix";
import { api } from "../../api";
import { Link, useParams, RouteComponentProps } from "react-router-dom";
import { TekkenScoreboard } from "../../components/TekkenScoreboard";
import { Button, Form, Container, Row, Col} from "react-bootstrap";

export const ScoreboardAdmin = (props: RouteComponentProps) => {
  const { id } = useParams();
  const [channel, setChannel] = useState<Channel>();
  const [round, setRound] = useState<string>();
  const [playerOne, setPlayerOne] = useState<Player>();
  const [playerTwo, setPlayerTwo] = useState<Player>();

  useEffect(() => {
    const init = async () => {
      const token = await api.getToken();
      const socket = new AppSocket(token);
      const [channel, initial_state] = socket.connect(id as String);
      const state = await initial_state;
      setRound(state.round);
      setPlayerOne(state.player_one);
      setPlayerTwo(state.player_two);
      channel.on("scoreboard_update", (update: { body: ScoreboardUpdate }) => {
        console.log("recieved", update);
        setRound(update?.body?.round);
        setPlayerOne(update.body.player_one);
        setPlayerTwo(update.body.player_two);
      });
      setChannel(channel);
    };
    init();
  }, [id]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("pushing");
    channel &&
      channel.push("scoreboard_update", {
        body: {
          round: round ? round : "",
          player_one: playerOne,
          player_two: playerTwo
        }
      });
  };

  const handleRound = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRound(e.target.value);
  };

  const swapPlayers = (e: React.FormEvent) => {
    e.preventDefault();
    var p1 = playerOne;
    setPlayerOne(playerTwo);
    setPlayerTwo(p1);
  };

  const PlayerElement = (setter: (p: Player) => void, value?: Player) =>
    value && (
      <><Row>
        <Form.Group as={Col} sm={3}>
          <Form.Label>
            Tag
          </Form.Label>
          <Form.Control value={value.tag} onChange={(e : any) =>
            setter({
              ...value,
              tag: e.target.value
            })
          }>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <Form.Label>
            Name
          </Form.Label>
          <Form.Control value={value.name} onChange={(e : any) =>
            setter({
              ...value,
              name: e.target.value
            })
          }>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm={2}>
          <Form.Label>
            Score
          </Form.Label>
          <Form.Control value={value.score ? value.score.toString() : ""} onChange={(e : any) =>
            setter({
              ...value,
              score: parseInt(e.target.value)
            })
          }
            type="number"
          >
          </Form.Control>
        </Form.Group>
        </Row>
      </>
    );

  return (
    <>
      <Container>
        <p>
          psst... if you want to see this scoreboard on obs, use this{" "}
          <Link to={`/scoreboards/view/${id}`}>link</Link>
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Round
            </Form.Label>
            <Col sm={5}>
              <Form.Control onChange={handleRound} value={round}></Form.Control>
            </Col>
          </Form.Group>
          {PlayerElement(setPlayerOne, playerOne)}
          {PlayerElement(setPlayerTwo, playerTwo)}
          <Button onClick={swapPlayers}>Swap Players</Button>
          <Button type="submit">update</Button>
        </Form>
      </Container>
      {round !== undefined && playerOne && playerTwo && (
        <TekkenScoreboard
          scoreboard={{ round, player_one: playerOne, player_two: playerTwo }}
        ></TekkenScoreboard>
      )}
    </>
  );
};
