var config: {
  HOST: string; WEBSOCKET_HOST: string;
  DISCORD_CLIENT_ID: string;
  DISCORD_PERMISSIONS: string;
}
switch (process.env.NODE_ENV) {
  case "production":
    config = require("./configs/production").default;
    break;
  default:
    config = require("./configs/development").default;
}
export default config;
