import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <p>Hey, there isn't a whole lot here yet</p>
      <p>
      Check out your scoreboards if you
      want <Link to="/scoreboards" >here</Link>
      </p>
    </div>
  );
};
