import axios, { AxiosInstance } from "axios";
import { User, Scoreboard } from "../entities";
import config from "../config";

const setToken = (token: string) => {
  sessionStorage.setItem("jwt_token", token);
  document.location.reload();
};

const logout = async () => {
  await client.delete<string>("/api/logout");
  sessionStorage.removeItem("jwt_token");
  document.location.reload();
};

const getApi = (client: AxiosInstance) => {
  return {
    getUser: () => client.get<User>("/api/me"),
    getScoreboards: () => client.get<Array<Scoreboard>>("/api/me/scoreboards"),
    createScoreboard: (scoreboard: { name: String; type: String }) =>
      client.post<Scoreboard>("/api/me/scoreboards", scoreboard),
    deleteScoreboard: (id: number) =>
      client.delete(`/api/me/scoreboards/${id}`),
    login: async (username: string, password: string) => {
      const token = await client.post<string>("/api/login", {
        user: { username, password }
      });
      setToken(token.data);
    },
    getToken: async () => {
      const token = await client.get<string>("/api/me/webhook_token");
      return token.data;
    },
    logout
  };
};

const client = axios.create({
  baseURL: config.HOST,
  withCredentials: true
});

export const api = getApi(client);
