import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NewScoreboardForm } from "../../components/NewScoreboardForm";
import { api } from "../../api";
import { Button } from "react-bootstrap"
import { Scoreboard } from "../../entities";
import { Table, Container, Row } from "react-bootstrap";

export const ScoreboardManager = () => {
  const [scoreboards, setScoreboards] = useState<Array<Scoreboard>>([]);

  const getScoreboards = async () => {
    const _scoreboards = await api.getScoreboards();
    setScoreboards(_scoreboards.data);
  };

  useEffect(() => {
    getScoreboards();
  }, [setScoreboards]);

  return (
    <Container>
      <Row>
        <NewScoreboardForm
          onChange={() => {
            getScoreboards();
          }}
        ></NewScoreboardForm>
      </Row>
      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>scoreboard</th>
              <th>type</th>
            </tr>
          </thead>
          <tbody>
            {scoreboards.map(scoreboard => (
              <tr>
                <td>{scoreboard.name}</td>
                <td>{scoreboard.type}</td>
                <td>
                  <Link to={`/scoreboards/manage/${scoreboard.id}`}>edit</Link>
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      api
                        .deleteScoreboard(scoreboard.id)
                        .then(() => getScoreboards());
                    }}
                  >
                    delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};
