import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext
} from "react";
import { User } from "../../entities";
import { api } from "../../api";

const AuthedContext = React.createContext<User | undefined>(undefined);

export const AuthenticatedProvider: FunctionComponent = props => {
  const [me, setMe] = useState<User>();

  useEffect(() => {
    const getMe = async () => {
      try {
        const user = await api.getUser();
        setMe(user.data);
      } catch (error) {
        setMe(undefined);
      }
    };
    getMe();
  }, []);

  return (
    <AuthedContext.Provider value={me}>{props.children}</AuthedContext.Provider>
  );
};

export const useAuth = () => useContext(AuthedContext);
