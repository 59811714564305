import React from "react";
import { useLocation } from "react-router-dom";
import { Login } from "../Login";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import config  from "../../config";

const AppNavbar = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname.match(/scoreboards\/view/) ? null : (
        <Navbar expand="lg">
          <Navbar.Brand>
            <LinkContainer to="/">
              <Nav.Item>
                <Link to="/">home</Link>
              </Nav.Item>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav>
              <Nav.Item>
                <Login></Login>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link target="_blank" href={`https://discordapp.com/api/oauth2/authorize?client_id=${config.DISCORD_CLIENT_ID}&permissions=${config.DISCORD_PERMISSIONS}&scope=bot`}>Discord Bot</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
};

export default AppNavbar;
