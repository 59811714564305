import styled, { css } from "styled-components";

export type Side = "left" | "right"

export type SideProps = {
  side: Side;
};

export const PlayerDiv = styled.span<SideProps>`
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0.9),
    rgba(31, 31, 31, 0.9)
  );
  display: inline-grid;
  grid-template-columns: 3fr minmax(100px, 150px);
  grid-template-areas: "name score";
  font-size: 38px;
  text-align: left;
  clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 5% 100%);
  ${props =>
    props.side === "right" &&
    css`
      clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
      text-align: right;
      grid-template-columns: minmax(100px, 150px) 3fr;
      grid-template-areas: "score name";
    `}
`;

export const PlayerTag = styled.span<SideProps>`
  grid-area: tag;
  color: red;
  ${props =>
    props.side === "right" &&
    css`
      color: #0077ff;
    `}
`;

export const PlayerName = styled.span<SideProps>`
  grid-area: name;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  margin: 0px 20px;
  margin-left: 50px;
  ${props =>
    props.side === "right" &&
    css`
      margin-left: 0px;
      margin-right: 50px;
    `}
`;

export const PlayerScore = styled.span<SideProps>`
  grid-area: score;
  color: white;
  background: linear-gradient(177deg, #d73202, #b91717);
  padding-top: 5px;
  text-align: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%);
  ${props =>
    props.side === "right" &&
    css`
      clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%);
      background: linear-gradient(185deg, #0053b6 100%, #0284d7 0);
    `}
`;

export const ScoreboardDiv = styled.span`
  display: grid;
  grid-template-columns: 2fr 200px 2fr;
  filter: drop-shadow(0 3px 4px #111);
  height: 70px;
`;

export const Round = styled.span`
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0.7),
    rgba(31, 31, 31, 0.8)
  );
  margin: 5px -40px;
  padding: 10px;
  color: #fff;
  height: auto;
  font-size: 18px;
`;
