import React from "react";
import { api } from "../../api";
import { Nav } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthenticatedContext";

export const Login = () => {
  const user = useAuth();

  return user ? (
    <Nav.Link onClick={api.logout}>Logout</Nav.Link>
  ) : (
    <Nav.Link href="/login">Login</Nav.Link>
  );
};
