import React, { useState } from "react";
import { api } from "../../api";
import { Form, Button } from "react-bootstrap";

export type NewScoreboardFormProps = {
  onChange: () => void;
};

export const NewScoreboardForm = (props: NewScoreboardFormProps) => {
  const [name, setName] = useState("");

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await api.createScoreboard({ name, type: "default" });
    setName("")
    props.onChange();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e: any) => {
            setName((e.target as any).value);
          }}
        ></Form.Control>
      </Form.Group>
      {/* <div>
        <label>Scoreboard type:</label>
        <select>
          <option>default</option>
        </select>
      </div> */}
      <Button variant="primary" type="submit">
        Create
      </Button>
    </Form>
  );
};
