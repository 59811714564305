import React, { useState, useEffect } from "react";
import { api } from "../../api";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";

export const Login = (props: RouteComponentProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    async function getUser() {
      try {
        await api.getUser();
        props.history.goBack();
      } catch (error) {
        return null;
      }
    }
    getUser();
  }, [props.history]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    api.login(username, password);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>Username</Form.Label>
          <Col sm={10}>
            <Form.Control onChange={(e : any) => { setUsername(e.target.value) } } placeholder="enter username"></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>Password</Form.Label>
          <Col sm={10}>
            <Form.Control type="password" onChange={(e : any) => { setPassword(e.target.value) } } placeholder="enter username"></Form.Control>
          </Col>
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
};
