import React from "react";
import { useAuth } from "../../contexts/AuthenticatedContext";
import { Route, RouteProps, useHistory } from "react-router-dom";
export const ProtectedRoute = (props: RouteProps) => {
  const user = useAuth();
  const history = useHistory();
  return user ? (<Route {...props}></Route>) : (<div>
    <div>not authenticated</div>
    <button onClick={() => history.goBack()}>go back</button>
  </div>);
};
